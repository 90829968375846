import { useEffect, useState } from 'react';

import config from '../constants/config';

import { titleFormatter } from '../helpers';

import useSharedContext from './useSharedContext';

const useStatic = (id, changeMetas) => {
    const [data, setData] = useState(null);
    const { setMetas } = useSharedContext();

    useEffect(() => {
        fetch(config.website_details.host + '/wp-json/wp/v2/pages/' + id)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setData(data);
                    !!changeMetas && setMetas(val => ({
                        ...val,
                        title: titleFormatter(data?.title?.rendered),
                    }));
                }
            });
    }, [id, changeMetas]); // eslint-disable-line

    return data;
}

export default useStatic;
