import React from 'react';

import Icon from '../icon';
import Animate from '../animate';

import useConfig from '../../hooks/useConfig';
import useContent from '../../hooks/useContent';

import './index.scss';

const Intro = () => {
    const config = useConfig();
    const content = useContent();

    const appName = config.website_details.appName;

    const children = content?.intro?.children
        .replace(
            'WHOODEN/STUDIOS',
            `<span class="inline-logo">${appName.split('/').map((word, index) => `<span>${index ? '/' : ''}${word}</span>`).join('')}</span>`
        );

    return (
        <div className="guide intro-container">
            <Animate>
                <div className="intro-holder">
                    <figure>
                        <img
                            alt=""
                            src={content.intro.media.image}
                        />
                        {content.intro.media.url && (
                            <a
                                className="absolutely-splash"
                                href={content.intro.media.url}
                                data-fancybox=""
                            >
                                <Icon
                                    size={92}
                                    type="icon-play"
                                />
                            </a>
                        )}
                    </figure>
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: children,
                        }}
                    />
                </div>
            </Animate>
        </div>
    );
};

export default Intro;
