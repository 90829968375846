import React from 'react';

import Animate from '../animate';

import useConfig from '../../hooks/useConfig';
import useStatic from '../../hooks/useStatic';
import useContent from '../../hooks/useContent';

import './index.scss';

const Footer = () => {
    const config = useConfig();
    const content = useContent();

    const data = useStatic('3');

    return (
        <Animate>
            <footer>
                <p>{content.copyright}</p>
                {(config.links.privacypolicy && !!data) && (
                    <a
                        className="link-effect"
                        href={config.links.privacypolicy.replace(config.website_details.host, '')}
                    >
                        {data?.title?.rendered}
                    </a>
                )}
            </footer>
        </Animate>
    );
};

export default Footer;
