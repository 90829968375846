import Contact from '../components/contact';
import Services from '../components/services';
import Manifesto from '../components/manifesto';

const slugs = [
    {
        key: 'manifesto',
        name: 'Manifesto',
        component: <Manifesto/>,
    },
    {
        key: 'services',
        name: 'Services',
        component: <Services/>,
    },
    {
        key: 'contact',
        name: 'Contact',
        component: <Contact/>
    },
];

export default slugs;
