import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Fancybox } from '@fancyapps/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Animate from '../animate';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import { defaultMetas, fancyConstructor, goTo, titleFormatter } from '../../helpers';

import './index.scss';

const ManifestoItem = props => {
    const {
        url,
        index,
        image,
        title,
        caption,
    } = props;

    const ref = useRef(null);
    const parentRef = useRef(null);
    const scrollRef = useRef(null);
    const [height, setHeight] = useState(null);
    const [projects, setProjects] = useState([]);

    const { push, replace } = useHistory();
    const location = useLocation();

    const { setMetas } = useSharedContext();

    useEffect(() => {
        if (height !== null) {
            goTo('', height ? scrollRef.current : parentRef.current, height ? 250 : 0);
            !height && setMetas(defaultMetas);
        }
    }, [height]); // eslint-disable-line

    const { isMobile } = useDevice();

    const titleRenderer = (
        <h4>
            {title.split(' ').map((word, index) => (
                <Fragment key={index}>
                    {isMobile ? (
                        <span>{word}</span>
                    ) : (
                        <>
                            {`${word} `}<br/>
                        </>
                    )}
                </Fragment>
            ))}
        </h4>
    );

    useEffect(() => {
        if (props?.projects?.length) {
            const localArr = [];
            props?.projects?.forEach(row => {
                if (row.length) {
                    const arr = [];
                    row.forEach(item => {
                        if (item.collection) {
                            arr.push({
                                ...item,
                                collection: fancyConstructor(item.collection),
                            });
                        } else {
                            arr.push(item);
                        }
                    });
                    localArr.push(arr);
                }
            });
            setProjects(localArr);
        }
    }, [props.projects]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (location.pathname.indexOf(url) !== -1) {
            setHeight(ref.current.clientHeight);
            setMetas(val => ({
                ...val,
                title: titleFormatter(title),
            }));
            const slide = searchParams.get('slide');
            if (!document.querySelector('.fancybox__container')) {
                projects.forEach(row => row.forEach(item => {
                    if (location.pathname.indexOf(item.url) !== -1) {
                        !!item.caption && setMetas(val => ({
                            ...val,
                            title: titleFormatter(`${item.caption} - ${title}`),
                        }));
                        Fancybox.show(item.collection, {
                            closeButton: 'outside',
                            on: {
                                'Carousel.selectSlide': (event, fancybox, slide) => {
                                    replace(`${item.url}?slide=${slide.index}`);
                                    const className = 'fancybox-custom-caption';
                                    const titleClassName = 'fancybox-project-title';
                                    if (!slide.$el.querySelector(`.${className}`)) {
                                        const meta = document.createElement('ul');
                                        meta.className = className;
                                        [item.collection?.[slide.index]?.left_caption, slide?.caption || '', `${slide.index + 1}/${item.collection.length}`].forEach(item => {
                                            const li = document.createElement('li');
                                            !!item && (li.innerText = item);
                                            meta.append(li);
                                        });
                                        slide.$el.querySelector('.fancybox__content').append(meta);
                                    }
                                    if (!fancybox.$element.querySelector(`.${titleClassName}`)) {
                                        const titleTag = document.createElement('div');
                                        titleTag.className = titleClassName;
                                        item.collection?.[slide.index]?.left_caption && (titleTag.innerText = item.collection?.[slide.index]?.left_caption);
                                        fancybox.$element.append(titleTag);
                                    }
                                },
                                'destroy': () => {
                                    push(url);
                                    setMetas(val => ({
                                        ...val,
                                        title: titleFormatter(title),
                                    }));
                                },
                            },
                        });
                        Fancybox.getInstance().jumpTo(parseFloat(slide), { friction: 0 });
                    }
                }));
            }
        }
        location.pathname === '/' && setMetas(defaultMetas);
    }, [location.pathname, location.search, ref.current, projects]); // eslint-disable-line

    return (
        <div className="manifesto-item-holder">
            <div className="guide">
                <Animate>
                    <ul
                        ref={parentRef}
                        onClick={() => {
                            setHeight(ref.current.clientHeight);
                            push(url);
                        }}
                        className={classnames(
                            `manifesto-item`,
                            {
                                'reverse': !(index % 2),
                                'cursor-pointer': !height,
                            }
                        )}
                    >
                        <li>
                            {isMobile && titleRenderer}
                            <figure>
                                <img
                                    src={image}
                                    alt={title}
                                />
                                <figcaption>{caption}</figcaption>
                            </figure>
                        </li>
                        <li>
                            {!isMobile && titleRenderer}
                            <div
                                className={classnames(
                                    `manifesto-item-cta-holder`,
                                    {
                                        'active': !height,
                                    }
                                )}
                            >
                                <div className="manifesto-item-cta">
                                    Discover →
                                </div>
                            </div>
                        </li>
                    </ul>
                </Animate>
            </div>
            <ul
                style={{
                    height,
                }}
                className={classnames(
                    `manifesto-projects-clip-overflow`,
                    {
                        'active': !!height,
                    }
                )}
            >
                <li ref={ref}>
                    <div
                        ref={scrollRef}
                        className="manifesto-projects"
                    >
                        {projects?.map((row, index) => !!row.length && (
                            <ul
                                key={index}
                                style={{
                                    '--child-count': row.length === 2 && row.some(i => i.wide) ? 3 : row.length,
                                }}
                                className={classnames(
                                    `c-${row.length}`,
                                    {
                                        'cols-2': row.length === 3 && row.some(i => !(!i.image && !i.children)),
                                        'empty-not-found': !row.some(i => (!i.image && !i.children)),
                                    }
                                )}
                            >
                                {row.map((cell, index) => (
                                    <li
                                        key={index}
                                        className={classnames(
                                            'user-select-none',
                                            {
                                                'wide': cell.wide,
                                                'clickable': !!cell?.collection?.length,
                                            }
                                        )}
                                        onClick={() => push(`${cell.url}?slide=0`)}
                                    >
                                        {!!cell?.collection?.length && cell?.collection?.map(item => item?.children && (
                                            <div
                                                id={item.id}
                                                key={item.id}
                                                className="cell-children-holder"
                                            >
                                                <div
                                                    className="cell-children"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.children,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                        {(cell.image || cell.children) && (
                                            <>
                                                <Animate>
                                                    {cell.image && (
                                                        <div className="mp-image-holder">
                                                            <img
                                                                alt={title}
                                                                src={cell.image}
                                                            />
                                                            {cell.caption && (
                                                                <div className="mp-caption">
                                                                    {cell.caption}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {cell.children && (
                                                        <div
                                                            className="mp-children-holder"
                                                            dangerouslySetInnerHTML={{
                                                                __html: cell.children,
                                                            }}
                                                        />
                                                    )}
                                                </Animate>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ))}
                        <div className="manifesto-projects-toggle">
                            <button
                                onClick={() => {
                                    setHeight(0);
                                    push('/');
                                }}
                                className="user-select-none"
                            >
                                <Icon
                                    size={20}
                                    type="icon-arrow-up"
                                />
                                <span>Collapse</span>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

ManifestoItem.propTypes = {
    url: PropTypes.string,
    index: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    projects: PropTypes.array,
};

export default ManifestoItem;
