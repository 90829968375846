import React, { useEffect } from 'react';
import useDencrypt from 'use-dencrypt-effect';

const Loader = () => {
    const values = ['loading', 'please wait', 'opening'];

    const { result, dencrypt } = useDencrypt();

    useEffect(() => {
        let i = 0;

        const action = setInterval(() => {
            dencrypt(values[i]);

            i = i === values.length - 1 ? 0 : i + 1;
        }, 2000);

        return () => clearInterval(action);
    }, []); // eslint-disable-line

    return (
        <div className="loader-holder">
            {result}
        </div>
    );
};

export default Loader;
