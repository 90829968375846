import React from 'react';

import Loader from '../loader';

import useStatic from '../../hooks/useStatic';

import './index.scss';

const Static = () => {
    const data = useStatic('3', true);

    return data ? (
        <div className="guide">
            <div className="static-content-holder">
                <h1>{data.title.rendered}</h1>
                <div
                    className="static-content"
                    dangerouslySetInnerHTML={{
                        __html: data.content.rendered,
                    }}
                />
            </div>
        </div>
    ) : (
        <Loader/>
    );
};

export default Static;
