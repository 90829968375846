const config = {
  "website_details": {
    "host": "https://whoodenstudios.com",
    "appName": "WHOODEN/STUDIOS",
    "description": "WHOODENSTUDIOS is a global research and creative studio for brands and institutions seeking strategies and activations that build community and make culture.",
    "keywords": "creative,agency",
    "mail": "info@whoodenstudios.com"
  },
  "affiliate": {
    "ed": {
      "url": "/ed",
      "type": "ed",
      "enabled": false
    },
    "lab": {
      "url": "/lab",
      "type": "lab",
      "enabled": false
    }
  },
  "links": {
    "newsletter": "http://eepurl.com/gZvd69",
    "privacypolicy": "https://whoodenstudios.com/privacy-policy"
  },
  "socials": {
    "social": [
      {
        "url": "https://www.facebook.com/whoodenstudios/",
        "platform": "facebook"
      },
      {
        "url": "https://www.instagram.com/whoodenstudios/",
        "platform": "Instagram"
      },
      {
        "url": "https://www.linkedin.com/company/whoodenstudios",
        "platform": "linkedin"
      },
      {
        "url": "https://vimeo.com/whoodenstudios",
        "platform": "vimeo"
      },
      {
        "url": "https://www.youtube.com/channel/UCjy-N9JYiPfcrbo-Md-aEHA",
        "platform": "youtube"
      },
      {
        "url": "https://www.tiktok.com/@whoodenstudios?",
        "platform": "tiktok"
      }
    ]
  },
  "intro": {
    "image": "https://whoodenstudios.com/wp-content/uploads/2021/08/india-alt-image-template-featured.jpg",
    "video": "https://vimeo.com/420675527",
    "description": "WHOODEN/STUDIOS is a global creative studio for brands and institutions seeking strategies and activations that create culture while growing community."
  },
  "services": {
    "strategy": "\"Creative Direction\",\r\n\"Branding & Design\",\r\n\"Campaign Development\",\r\n\"Casting & Social Strategy\",\r\n\"Community Development\",\r\n\"Gaming Partnerships\",\r\n\"Wellness Activations\",\r\n\"E-Commerce Experience\""
  }
}; export default config;