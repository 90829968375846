import React from 'react';
import classnames from 'classnames';
import Icon from '../icon';

import Socials from '../socials';
import Animate from '../animate';

import useDevice from '../../hooks/useDevice';
import useConfig from '../../hooks/useConfig';

import './index.scss';

const Contact = () => {
    const { isMobile } = useDevice();
    const config = useConfig();

    return (
        <Animate>
            <ul className="contact-holder">
                <li>
                    <ul className="contact-details">
                        {!!config.links.newsletter && (
                            <li>
                                <a
                                    href={config.links.newsletter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classnames(
                                        `contact-newsletter`,
                                        {
                                            'link-effect': !isMobile,
                                        }
                                    )}
                                >
                                    <span>Subscribe to our newsletter</span>
                                    {isMobile && (
                                        <Icon
                                            size={12}
                                            type="icon-arrow-right-long"
                                        />
                                    )}
                                </a>
                            </li>
                        )}
                        <li>
                            <Socials/>
                        </li>
                    </ul>
                </li>
                <li>
                    {!!config.website_details.mail && (
                        <a
                            href={`mailto:${config.website_details.mail}`}
                            className="link-effect hover-active contact-email"
                        >
                            {config.website_details.mail}
                        </a>
                    )}
                </li>
            </ul>
        </Animate>
    );
};

export default Contact;
