import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';

import Intro from '../intro';
import Header from '../header';
import Footer from '../footer';
import Loader from '../loader';
import Static from '../static';
import Animate from '../animate';
import Observer from '../observer';

import slugs from '../../constants/slugs';
import config from '../../constants/config';

import { defaultMetas, isStaticPage } from '../../helpers';

import SharedContext from '../../sharedContext';

const Layout = () => {
    const [activeSection, setActiveSection] = useState('');
    const [content, setContent] = useState(null);
    const [fusionConfig, setFusionConfig] = useState(null);

    const location = useLocation();

    useEffect(() => {
        fetch(config.website_details.host + '/wp-json/api/content')
            .then(response => response.json())
            .then(data => {
                let obj = {};
                data.forEach(item => {
                    obj = {
                        ...obj,
                        ...item,
                    };
                });
                setContent(obj);
            });
    }, []); // eslint-disable-line

    useEffect(() => {
        fetch(config.website_details.host + '/wp-json/api/v3/pages/5')
            .then(response => response.json())
            .then(data => setFusionConfig(data.acf));
    }, []); // eslint-disable-line

    const [metas, setMetas] = useState(defaultMetas);

    const value = {
        content,
        setMetas,
        fusionConfig,
        activeSection,
        setActiveSection,
    };

    return content ? (
        <SharedContext.Provider value={value}>
            <Helmet>
                <title>{metas.title}</title>
                <meta content={metas.title} name='title'/>
                <meta content={metas.title} itemProp='name'/>
                <meta content={metas.title} property='og:title'/>
                <meta content={metas.title} name='twitter:title'/>
                <meta content={metas.description} name='description'/>
                <meta content={metas.description} itemProp='description'/>
                <meta content={metas.description} property='og:description'/>
                <meta content={metas.description} name='twitter:description'/>
                <meta content={metas.keywords} name='keywords'/>
                <meta content={window.location.href} property='og:url'/>
                <link href={window.location.href} rel='alternate' hrefLang='x-default'/>
                <link href={window.location.href} rel='canonical'/>
            </Helmet>
            <Header/>
            {isStaticPage(location) ? (
                <>
                    <Static/>
                </>
            ) : (
                <>
                    <Intro/>
                    {slugs.map((item, index) => (
                        <Observer
                            key={index}
                            slug={item.key}
                        >
                            <div className="guide">
                                <Animate>
                                    <h2>
                                        <small>{`0${index + 1}`}</small>
                                        {item.name}
                                    </h2>
                                </Animate>
                            </div>
                            {item.component}
                        </Observer>
                    ))}
                    <Footer/>
                </>
            )}
        </SharedContext.Provider>
    ) : (
        <Loader/>
    );
};

export default Layout;
