export const goTo = (slug, element = document.querySelector(`[data-slug="${slug}"]`), timeout = 0) => {
    const headerSize = document.querySelector('header').getBoundingClientRect().height;
    setTimeout(() => window.scrollTo({
        top: (element.getBoundingClientRect().top + window.scrollY) - headerSize,
        behavior: 'smooth',
    }), timeout);
};

export const conditionalSpread = (spread, condition, fallback = []) => !!condition ? spread : fallback;

export const backgroundImage = url => conditionalSpread({ backgroundImage: `url(${url})` }, url, {});

export const rem = number => `${number / 10}rem`;

const hash = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const guid = () => hash() + hash() + '-' + hash() + '-' + hash() + '-' + hash() + '-' + hash() + hash() + hash();

export const fancyConstructor = arr => arr.map(item => {
    const id = item.id || guid();

    return ({
        id,
        caption: item.caption,
        left_caption: item?.left_caption,
        src: item.type === 'image' ? item.src : item.type === 'html' ? item.video_path : `#${id}`,
        ...conditionalSpread({
            children: item.text,
        }, item.text, {}),
        type: item.type === 'image' ? 'image' : item.type === 'html' ? 'html' : 'inline',
    })
});

export const defaultMetas = {
    title: process.env.REACT_APP_NAME,
    description: process.env.REACT_APP_DESCRIPTION,
    keywords: process.env.REACT_APP_KEYWORDS,
};

export const titleFormatter = title => `${title} | ${process.env.REACT_APP_NAME}`;

export const isStaticPage = location => location?.pathname !== '/' && location?.pathname?.indexOf('/privacy-policy') !== -1;
