import React, { useState } from 'react';
import classnames from 'classnames';

import Animate from '../animate';

import useDevice from '../../hooks/useDevice';
import useContent from '../../hooks/useContent';

import './index.scss';

const Services = () => {
    const [active, setActive] = useState(null);

    const content = useContent();

    const { isMobile } = useDevice();

    return (
        <div className="guide">
            {isMobile ? (
                <ul className="services-mobile-holder">
                    {content.services.map((service, index) => (
                        <li key={index}>
                            <Animate>
                                <h3>{service.name}</h3>
                            </Animate>
                            <ul className="smb-content">
                                {service.children.map((subService, index) => (
                                    <li key={index}>
                                        <Animate>
                                            {subService}
                                        </Animate>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : (
                <Animate>
                    <ul className="services-holder">
                        <li>
                            {content.services.map((service, index) => (
                                <h3
                                    key={index}
                                    onMouseEnter={() => setActive(index)}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    {service.name}
                                </h3>
                            ))}
                        </li>
                        <li>
                            {content.services.map((service, index) => (
                                <ul
                                    key={index}
                                    className={classnames(
                                        `service-sub-holder`,
                                        {
                                            'active': index === active,
                                        }
                                    )}
                                >
                                    {service.children.map((subService, index) => (
                                        <li key={index}>
                                            {subService}
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </li>
                    </ul>
                </Animate>
            )}
        </div>
    );
};

export default Services;
