import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Socials from '../socials';

import useContent from '../../hooks/useContent';

import slugs from '../../constants/slugs';

import { goTo } from '../../helpers';

import './index.scss';

const Navigation = props => {
    const {
        menuOpened,
        setMenuOpened,
    } = props;

    const content = useContent();

    return (
        <ul
            className={classnames(
                `navigation-holder`,
                {
                    'active': menuOpened,
                }
            )}
        >
            <li>
                <div className='navigation-footnote'>
                    <Socials/>
                    <p>{content.copyright}</p>
                </div>
            </li>
            <li>
                <ul className='navigation'>
                    {slugs.map((item, index) => (
                        <li
                            key={index}
                            className="user-select-none"
                            onClick={() => {
                                goTo(item.key);
                                setMenuOpened(false);
                            }}
                        >
                            <small>{`0${index + 1}`}</small>
                            <span>{item.name}</span>
                        </li>
                    ))}
                </ul>
            </li>
        </ul>
    );
};

Navigation.propTypes = {
    menuOpened: PropTypes.bool,
    setMenuOpened: PropTypes.func,
};

export default Navigation;
