import { NavLink, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import classnames from 'classnames';

import Icon from '../icon';
import Animate from '../animate';
import Navigation from '../navigation';

import { goTo, isStaticPage } from '../../helpers';

import useConfig from '../../hooks/useConfig';
import { useScrollDirection } from '../../hooks/useScrollDirection';

import './index.scss';

const Header = () => {
    const config = useConfig();
    const [menuOpened, setMenuOpened] = useState(false);
    const scrollDirection = useScrollDirection();
    const location = useLocation();

    return (
        <header
            className={classnames({
                'hide': scrollDirection === 'down' && !menuOpened,
            })}
        >
            <Animate>
                <ul className="header-content">
                    <li>
                        {[config.affiliate.ed, config.affiliate.lab].map((item, index) => item.enabled && (
                            <ul
                                key={index}
                                className={classnames(
                                    `hc-a-branch`,
                                    `hc-toggle-struct`,
                                    `t-${item.type}`,
                                    {
                                        'pointer-events-none': !item.enabled,
                                    }
                                )}
                            >
                                <li/>
                                {item.enabled && (
                                    <li>
                                        <a href={item.url}>
                                            {item.type}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        ))}
                    </li>
                    <li>
                        <NavLink
                            to="/"
                            className="logo-holder user-select-none"
                            onClick={() => goTo('', document.documentElement)}
                        >
                            {config.website_details.appName.split('/').map((word, index) => (
                                <span key={index}>
                                    {!!index && '/'}
                                    {word}
                                </span>
                            ))}
                        </NavLink>
                    </li>
                    <li>
                        {!isStaticPage(location) && (
                            <div>
                                <Navigation
                                    menuOpened={menuOpened}
                                    setMenuOpened={setMenuOpened}
                                />
                                <ul
                                    className={classnames(
                                        'menu-toggle',
                                        'hc-toggle-struct',
                                        `user-select-none`,
                                        {
                                            'active': menuOpened,
                                        }
                                    )}
                                    onClick={() => setMenuOpened(val => !val)}
                                >
                                    <li/>
                                    <li>
                                        <Icon
                                            size={34}
                                            type="icon-close"
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                    </li>
                </ul>
            </Animate>
        </header>
    );
};

export default Header;
