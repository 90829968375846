import useSharedContext from './useSharedContext';

import config from '../constants/config';

const useContent = () => {
    const {
        fusionConfig,
    } = useSharedContext();

    return {
        ...config,
        ...fusionConfig,
    };
};

export default useContent;
