import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { conditionalSpread, rem } from '../../helpers';

import './index.scss';

const Icon = React.forwardRef(function Icon(props, ref) {
    const {
        type,
        size,
        onClick,
        className,
        onMouseEnter,
        onMouseLeave,
    } = props;

    return (
        <i
            className={classnames(
                `icon`,
                type,
                className,
                `user-select-none`,
                {
                    'pointer-events-none': !onClick && !onMouseEnter && !onMouseLeave,
                }
            )}
            ref={ref}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={conditionalSpread({ '--size': `var(--icon-size, ${rem(size)})` }, size)}
        />
    );
});

Icon.propTypes = {
    type: PropTypes.string,
    size: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default Icon;
