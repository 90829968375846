import useConfig from './useConfig';
import useSharedContext from './useSharedContext';

const useContent = () => {
    const {
        content,
    } = useSharedContext();

    const config = useConfig();

    return {
        ...content,
        copyright: `© ${new Date().getFullYear()} Copyright All Rights Reserved ${config.website_details.appName}`,
    };
};

export default useContent;
