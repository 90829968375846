import React from 'react';

import Icon from '../icon';

import useConfig from '../../hooks/useConfig';

import './index.scss';

const Socials = () => {
    const config = useConfig();

    return (
        <div className="socials-holder">
            {config.socials.social.map((social, index) => !!social.url && (
                <a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon
                        size={28}
                        type={`icon-${social.platform.toLowerCase()}`}
                    />
                </a>
            ))}
        </div>
    );
};

export default Socials;
