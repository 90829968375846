import React from 'react';

import ManifestoItem from '../manifestoItem';

import useContent from '../../hooks/useContent';

import './index.scss';

const Manifesto = () => {
    const content = useContent();

    return (
        <div className="manifesto-holder">
            {content.manifesto.map((item, index) => (
                <ManifestoItem
                    key={index}
                    index={index}
                    url={item.cat_url}
                    image={item.image}
                    title={item.title}
                    caption={item.caption}
                    projects={item.projects}
                />
            ))}
        </div>
    );
};

export default Manifesto;
